body {
    background-color: #e6f7ff;
    font-family: 'Comic Sans MS', cursive, sans-serif;
    margin: 0;
    padding: 0;
    background-image: url("../assets/carte.png");
    backdrop-filter: blur(2px);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.treasure-map-container {
    text-align: center;
    padding: 20px;
}

.title {
    color: #ffffff; /* Couleur blanche pour contraster avec le fond bleu */
    font-size: 3em;
    margin-bottom: 20px;
    background-color: rgba(0, 0, 139, 0.7); /* Bleu foncé avec transparence */
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-family: 'Comic Sans MS', cursive, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.subtitle {
    color: #ffffff; /* Couleur blanche pour contraster avec le fond bleu */
    font-size: 2em;
    margin-top: 30px;
    text-align: left;
    margin-left: 5%;
    background-color: rgba(0, 0, 139, 0.7); /* Bleu foncé avec transparence */
    padding: 5px 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-family: 'Comic Sans MS', cursive, sans-serif;
}

.chapter-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 5%;
}

.chapter-square {
    background-color: #ffe4c4;
    border-radius: 30px;
    padding: 20px;
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    margin: 10px;
}

.chapter-square:hover {
    transform: scale(1.1);
    background-color: #ffd700;
}

.chapter-icon img {
    width: 60px;
    height: 60px;
}

.chapter-title {
    margin-top: 10px;
    color: #00008b;
    font-size: 1.2em;
    text-align: center;
}

.treasure-chest {
    margin-top: 30px;
}

.treasure-chest img {
    width: 100px;
    height: 100px;
}

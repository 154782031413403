.course {
    padding: 20px;
    border: 1px solid #ddd;
    margin: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.course-image {
    width: 100%;
    height: auto;
    margin-top: 20px;
    border-radius: 5px;
}

.course h2 {
    color: #ffcc00; /* Couleur du titre en jaune */
}

.course p {
    font-size: 16px;
    line-height: 1.5;
}

.code-example {
    background-color: #2d2d2d; /* Couleur de fond du code */
    color: #dcdcdc; /* Couleur du texte du code */
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
}

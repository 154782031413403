body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  text-align: center;
}

hr {
  margin: 32px 0;
}

button, input {
  border-radius: 8px;
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  margin: 8px 0;
  padding: 8px;
  width: 100%;
}

input {
  border: 1px solid black;
}

button {
  border: 1px solid black;
  cursor: pointer;
}

.page-container {
  display: flex;
  background-color: #ddd;
  min-height: 100vh;
  min-width: 100%;
  align-items: center;
  justify-content: center;
}

.content-container {
  background-color: white;
  border-radius: 8px;
  min-width: 300px;
  max-width: 800px;
  padding: 32px;
}

.success {
  background-color: #90ee90;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 16px;
  text-align: center;
}

.fail {
  background-color: #ffcccb;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 16px;
  text-align: center;
}

.santa-container {
    text-align: center;
    padding: 20px;
    background-color: #f4f4f9;
    background-image: url('https://www.transparenttextures.com/patterns/snow.png');
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.santa-title {
    font-family: 'Pacifico', cursive;
    font-size: 2.5rem;
    color: #d32f2f;
    margin-bottom: 30px;
}

.button-group {
    display: flex;
    gap: 15px;
}

.christmas-button {
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    border: none;
    border-radius: 15px;
    padding: 15px 30px;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s, box-shadow 0.2s;
}

.christmas-button:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

.christmas-button:active {
    transform: scale(0.95);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.justine-container {
    text-align: center;
    background-color: #e0f7fa;
    padding: 50px;
    font-family: 'Arial', sans-serif;
    color: #004d40;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('https://www.transparenttextures.com/patterns/holiday.png');
}

h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #b71c1c;
}

p {
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.christmas-link {
    font-size: 1.5rem;
    color: #004d40;
    text-decoration: none;
    background-color: #ffab91;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-block;
    max-width: 300px;
    text-align: center;
}

.christmas-link:hover {
    background-color: #ff7043;
    transform: scale(1.1);
}

.real-button {
    margin-top: 400px;
    font-size: 1rem;
    color: white;
    background-color: #00796b;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: auto;
    max-width: 300px;
    text-align: center;
}

.real-button:hover {
    background-color: #004d40;
    transform: scale(1.05);
}

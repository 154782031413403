/* Container principal */
.level-container {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

/* Section de l'exercice de code */
.code-challenge {
    width: 60%;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 10px;
    margin-right: 20px;
}

/* Section du cours */
.course {
    width: 30%;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-left: 20px;
}

.course h2 {
    font-size: 1.5em;
    color: #333;
}

.course p {
    font-size: 1.2em;
    color: #555;
}

.code-example {
    background-color: #2d2d2d;
    color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1em;
}
body {
    background-color: #e6f7ff;
    font-family: 'Comic Sans MS', cursive, sans-serif;
    margin: 0;
    padding: 0;
}

.code-challenge {
    text-align: center;
    padding: 20px;
}

.progress-bar {
    width: 80%;
    background-color: #ddd;
    border-radius: 10px;
    margin: 10px auto;
}

.progress {
    height: 20px;
    background-color: #4caf50;
    border-radius: 10px;
}

.help-button {
    margin: 10px;
    padding: 5px 10px;
    background-color: #1e90ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.help-button:hover {
    background-color: #104e8b;
}

.run-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.run-button:hover {
    background-color: #45a049;
}

.back-to-home-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff4500;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.back-to-home-button:hover {
    background-color: #e03c00;
}

.help-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #1e90ff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 1000;
}

.help-content {
    text-align: center;
}

.help-content h3 {
    color: #ff4500;
    margin-bottom: 10px;
}

.help-content p {
    color: #00008b;
    margin-bottom: 20px;
}

.help-content button {
    padding: 5px 10px;
    background-color: #1e90ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.help-content button:hover {
    background-color: #104e8b;
}

.success-message {
    margin-top: 20px;
    color: green;
    font-size: 1.5em;
}

.next-challenge-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.next-challenge-button:hover {
    background-color: #45a049;
}

